import React from 'react'

import { useAb } from 'hooks'
import { constants } from 'helpers'

import SubscribeHeaderBanner from 'compositions/banners/SubscribeHeaderBanner/SubscribeHeaderBanner'
import SubscribeHeaderBannerAb from 'compositions/banners/SubscribeHeaderBannerAb/SubscribeHeaderBannerAb'
import useBanner from './util/useBanner'
import CardUpdateBanner from './components/CardUpdateBanner/CardUpdateBanner'
import UndeliverableBanner from './components/UndeliverableBanner/UndeliverableBanner'
import BannersCarousel from './components/BannersCarousel/BannersCarousel'
import TossInBanner from './components/TossInBanner/TossInBanner'
import PrivateSaleBanner from './components/PrivateSaleBanner/PrivateSaleBanner'
import UnskipBanner from './components/UnskipBanner/UnskipBanner'
import CandleSubscriptionBanner from './components/CandleSubscriptionBanner/CandleSubscriptionBanner'
import HolidaySeasonBanner from './components/HolidaySeasonBanner/HolidaySeasonBanner'
import BlackFridayBanner from './components/BlackFridayBanner/BlackFridayBanner'
import CyberMondayBanner from './components/CyberMondayBanner/CyberMondayBanner'
import MothersDayBanner from './components/MothersDayBanner/MothersDayBanner'
import FathersDayBanner from './components/FathersDayBanner/FathersDayBanner'
import ResubscribeBanner from './components/ResubscribeBanner/ResubscribeBanner'
import DriftSubscriptionTextBanner from './components/DriftSubscriptionTextBanner/DriftSubscriptionTextBanner'
import BfcmBanner from './components/BfcmBanner/BfcmBanner'
import GiftSubscriptionBanner from './components/GiftSubscriptionBanner/GiftSubscriptionBanner'
import GwpTextBanner from './components/GwpTextBanner/GwpTextBanner'
import MansGroomingBanner from './components/MansGroomingBanner/MansGroomingBanner'
import TheSummerEditBanner from './components/TheSummerEditBanner/TheSummerEditBanner'
import VipSubscriberSaleBanner from './components/VipSubscriberSaleBanner/VipSubscriberSaleBanner'
import CandleSaleBanner from './components/CandleSaleBanner/CandleSaleBanner'


const Banner: React.FunctionComponent = () => {
  const {
    isFetching,
    isCardUpdateBannerVisible,
    isSubscribeBannerVisible,
    isUndeliverableBannerVisible,
    isTossInBannerVisible,
    isPrivateSaleBannerVisible,
    isUnskipBannerVisible,
    isCandleSubscriptionBannerVisible,
    isHolidayBannerVisible,
    holidayBannerDiscount,
    isHolidayMothersDayVisible,
    isHolidayFathersDayVisible,
    isBlackFridayBannerVisible,
    isCyberMondayBannerVisible,
    isResubscribeBannerVisible,
    isDriftSubscriptionVisible,
    isDriftSubscriptionHasLaunchPriority,
    isBfcmBannerVisible,
    isGiftSubscriptionBannerVisible,
    isGwpTopBarVisible,
    isMansGroomingTopBarVisible,
    isTheSummerEditTopBarVisible,
    isVipSubscriberSaleTopBarVisible,
    isCandleSaleTopBarVisible,
  } = useBanner()

  const isSubscribeBannerAbEnabled = useAb(constants.abTests.globalSubscribeBannerUpdate) === 'b'

  if (isFetching) {
    return null
  }

  if (isUnskipBannerVisible) {
    return (
      <UnskipBanner />
    )
  }

  if (isCardUpdateBannerVisible) {
    return (
      <CardUpdateBanner />
    )
  }

  if (isUndeliverableBannerVisible) {
    return (
      <UndeliverableBanner />
    )
  }

  if (isResubscribeBannerVisible) {
    return (
      <ResubscribeBanner />
    )
  }

  if (isHolidayMothersDayVisible) {
    return (
      <MothersDayBanner />
    )
  }

  if (isBlackFridayBannerVisible) {
    return (
      <BlackFridayBanner />
    )
  }

  if (isCyberMondayBannerVisible) {
    return (
      <CyberMondayBanner />
    )
  }

  if (isSubscribeBannerVisible) {
    return isSubscribeBannerAbEnabled ? (
      <SubscribeHeaderBannerAb />
    ) : (
      <SubscribeHeaderBanner />
    )
  }

  if (isDriftSubscriptionVisible && isDriftSubscriptionHasLaunchPriority) {
    return (
      <DriftSubscriptionTextBanner />
    )
  }

  if (isHolidayBannerVisible) {
    return <HolidaySeasonBanner key="holidayHub" />
  }

  if (isMansGroomingTopBarVisible) {
    return <MansGroomingBanner key="mansGrooming" />
  }

  if (isTheSummerEditTopBarVisible) {
    return <TheSummerEditBanner key="theSummerEdit" />
  }

  if (isVipSubscriberSaleTopBarVisible) {
    return <VipSubscriberSaleBanner key="vipSubscriberSale" />
  }

  if (isCandleSaleTopBarVisible) {
    return <CandleSaleBanner key="candleSale" />
  }

  const banners = []

  if (isGwpTopBarVisible) {
    banners.push(
      <GwpTextBanner key="gwpBanner" position={0} />
    )
  }

  if (isBfcmBannerVisible) {
    banners.push(
      <BfcmBanner key="BfcmBanner" position={banners.length + 1} />
    )
  }

  if (isGiftSubscriptionBannerVisible) {
    banners.push(
      <GiftSubscriptionBanner key="GiftSubscriptionBanner" position={banners.length + 1} />
    )
  }

  if (isDriftSubscriptionVisible) {
    banners.push(<DriftSubscriptionTextBanner key="driftSubscription" position={banners.length + 1} />)
  }

  if (isHolidayMothersDayVisible) {
    banners.push(<MothersDayBanner key="holidayMothersDay" position={banners.length + 1} />)
  }

  if (isHolidayFathersDayVisible) {
    banners.push(<FathersDayBanner key="holidayFathersDay" position={banners.length + 1} />)
  }

  if (holidayBannerDiscount > 0) {
    banners.push(<HolidaySeasonBanner key="holidayDiscount" discount={holidayBannerDiscount} position={banners.length + 1} />)
  }

  if (isCandleSubscriptionBannerVisible) {
    banners.push(<CandleSubscriptionBanner key="candleSubscription" position={banners.length + 1} />)
  }

  if (isTossInBannerVisible) {
    banners.push(<TossInBanner key="tossIn" position={banners.length + 1} />)
  }

  if (isPrivateSaleBannerVisible) {
    banners.push(<PrivateSaleBanner key="privateSale" position={banners.length + 1} />)
  }

  return (
    <BannersCarousel>
      {banners}
    </BannersCarousel>
  )
}


export default Banner
